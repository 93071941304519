<template>
  <div>
    <b-card title="Quản lý chương trình khuyến mãi">
      <div class="search-page-promotion">
        <b-row>
          <b-col lg="3">
            <b-form-group>
              <label class="font-weight-bold font-size-label-custom">Tên chương trình</label>
              <b-form-input
                v-model="modelPromotionSearch.name"
                placeholder="Nhập tên chương trình để tìm kiếm"
                @keyup.enter="getListPromotion"
              />
            </b-form-group>
          </b-col>
          <b-col lg="2">
            <div class="start-date-search">
              <label class="font-weight-bold font-size-label-custom">Ngày bắt đầu</label>
              <flat-pickr
                v-model="modelPromotionSearch.startDate"
                class="form-control"
                :config="configFlatpickr"
                @input="getListPromotion"
              />
            </div>
          </b-col>
          <b-col lg="2">
            <div class="end-date-search">
              <label class="font-weight-bold font-size-label-custom">Ngày kết thúc</label>
              <flat-pickr
                v-model="modelPromotionSearch.endDate"
                class="form-control"
                :config="configFlatpickr"
                @input="getListPromotion"
              />
            </div>
          </b-col>
          <b-col lg="2">
            <div class="acction-search-promotion">
              <b-button
                v-ripple.400="'rgba(113, 102, 240, 0.15)'"
                variant="outline-primary"
                @click="getListPromotion"
              >
                <feather-icon
                  icon="SearchIcon"
                  class="mr-50"
                />
                <span class="align-middle">Tìm kiếm</span>
              </b-button>
            </div>
          </b-col>
        </b-row>
      </div>
      <b-row>
        <b-col
          lg="12"
          xs="12"
          md="12"
        >
          <div class="add-promotion">
            <b-button
              v-ripple.400="'rgba(113, 102, 240, 0.15)'"
              variant="outline-primary"
              @click="getFormAddPromotion"
            >
              <feather-icon
                icon="PlusIcon"
                class="mr-50"
              />
              <span class="align-middle">Thêm mới</span>
            </b-button>
          </div>
        </b-col>
      </b-row>
      <b-row>
        <b-col
          lg="12"
          md="12"
          xs="12"
        >
          <div class="data-table-promotion">
            <span class="custom-span">Có tổng số {{ totalCount }} bản ghi</span>
            <b-table
              :items="listPromotion"
              :current-page="currentPage"
              :per-page="perPage"
              :fields="fields"
            >
              <template #cell(index)="data">
                <span>{{ data.index + 1 }}</span>
              </template>
              <template #cell(hanhdong)="data">
                <b-button
                  v-b-tooltip.hover.top="'Cập nhật'"
                  variant="flat-primary"
                  class="btn-icon"
                  @click="getFormUpdatePromotion(data.item)"
                >
                  <feather-icon icon="EditIcon" />
                </b-button>
                <b-button
                  v-b-tooltip.hover.top="'Xóa'"
                  variant="flat-danger"
                  class="btn-icon"
                  @click="deletePromotion(data.item)"
                >
                  <feather-icon icon="TrashIcon" />
                </b-button>
              </template>
            </b-table>
            <b-pagination
              v-model="currentPage"
              :total-rows="rows"
              class="float-right mt-2"
              :per-page="perPage"
              hide-goto-end-buttons
            />
          </div>
        </b-col>
      </b-row>
    </b-card>
    <b-modal
      ref="modal-promotion"
      size="lg"
      hide-footer
      hide-header
      ok-only
    >
      <div class="header-modal-promotion">
        <h3>{{ isPromotion? 'Cập nhật' : 'Thêm mới' }} chương trình khuyến mãi</h3>
      </div>
      <div class="main-body-page">
        <validation-observer ref="modelPromotion">
          <div class="information-default">
            <b-row>
              <b-col lg="6">
                <b-form-group>
                  <validation-provider
                    #default="{ errors }"
                    name="namePromotion"
                    rules="required"
                  >
                    <label class="font-weight-bold font-size-label-custom">Tên chương trình <span class="text-danger">(*)</span></label>
                    <b-form-input
                      v-model="modelPromotion.name"
                      placeholder="Nhập tên chương trình khuyến mãi"
                    />
                    <small class="text-danger">{{ errors[0] }}</small>
                  </validation-provider>
                </b-form-group>
              </b-col>
            </b-row>
            <b-row>
              <b-col lg="6">
                <b-form-group>
                  <validation-provider
                    #default="{ errors }"
                    name="contentPromotion"
                    rules="max:500"
                  >
                    <label class="font-size-label-custom font-weight-bold">Nội dung</label>
                    <b-form-textarea
                      v-model="modelPromotion.content"
                      placeholder="Nhập nội dung chương trình"
                    />
                    <small class="text-danger">{{ errors[0] }}</small>
                  </validation-provider>
                </b-form-group>
              </b-col>
            </b-row>
            <b-row>
              <b-col lg="3">
                <div class="date-start">
                  <b-form-group>
                    <label class="font-weight-bold font-size-label-custom">Ngày bắt đầu</label>
                    <flat-pickr
                      v-model="modelPromotion.startDate"
                      class="form-control"
                      :config="configFlatpickr"
                      @input="onCompareFormDateToDate"
                    />
                  </b-form-group>
                </div>
              </b-col>
              <b-col lg="3">
                <div class="date-end">
                  <div class="date-start">
                    <b-form-group>
                      <label class="font-weight-bold font-size-label-custom">Ngày kết thúc</label>
                      <flat-pickr
                        v-model="modelPromotion.endDate"
                        class="form-control"
                        :config="configFlatpickr"
                        @input="onCompareFormDateToDate"
                      />
                    </b-form-group>
                  </div>
                </div>
              </b-col>
            </b-row>
            <b-row>
              <b-col lg="6">
                <div
                  v-if="isValidateDate"
                  class="error-date"
                >
                  <span class="text-danger">Ngày kết thúc phải lớn hơn ngày bắt đầu</span>
                </div>
              </b-col>
            </b-row>
          </div>
          <div class="infomation-detail mt-1">
            <h4>Thông tin chi tiết chương trình khuyến mãi</h4>
            <div class="main-detail mt-1">
              <b-form
                class="repeater-form"
              >
                <b-row
                  v-for="(item) in modelPromotion.promotionSchoolDetails"
                  :id="item.id"
                  :key="item.id"
                >
                  <b-col lg="2">
                    <div class="start-date-detail">
                      <b-form-group>
                        <label class="font-weight-bold font-size-label-custom">Ngày bắt đầu</label>
                        <flat-pickr
                          v-model="item.fromDate"
                          class="form-control"
                          :config="configFlatpickr"
                          @input="onCompareDate"
                        />
                      </b-form-group>
                    </div>
                  </b-col>
                  <b-col lg="2">
                    <div class="end-date-detail">
                      <b-form-group>
                        <label class="font-weight-bold font-size-label-custom">Ngày kết thúc</label>
                        <flat-pickr
                          v-model="item.toDate"
                          class="form-control"
                          :config="configFlatpickr"
                          @input="onCompareDate"
                        />
                      </b-form-group>
                      <span
                        v-if="item.isValidate === true"
                        class="text-danger"
                      >Ngày kết thúc phải lớn hơn ngày bắt đầu</span>
                    </div>
                  </b-col>
                  <b-col lg="2">
                    <div class="percent-reduce">
                      <b-form-group>
                        <label class="font-weight-bold font-size-label-custom">Giảm trừ % <span class="text-danger">(*)</span></label>
                        <b-form-input
                          v-model="item.percent"
                          @change="onChangeFormatNumber(item.percent)"
                        />
                      </b-form-group>
                    </div>
                  </b-col>
                  <b-col lg="4">
                    <div class="type-promotion">
                      <b-form-group>
                        <label class="font-weight-bold font-size-label-custom">Loại khuyến mãi <span class="text-danger">(*)</span></label>
                        <v-select
                          v-model="item.type"
                          placeholder="Chọn loại khuyến mãi"
                          :options="listTypePromotion"
                          :reduce="(type) => type.value"
                          label="name"
                        />
                      </b-form-group>
                    </div>
                  </b-col>
                  <b-col lg="2">
                    <div class="acction-promotion-detail">
                      <b-button
                        v-ripple.400="'rgba(40, 199, 111, 0.15)'"
                        variant="flat-success"
                        class="btn-icon rounded-circle mt-0 mt-md-2"
                        @click="repeatAgain()"
                      >
                        <feather-icon icon="PlusIcon" />
                      </b-button>
                      <b-button
                        v-if="item.id > 1"
                        v-ripple.400="'rgba(234, 84, 85, 0.15)'"
                        variant="flat-danger"
                        class="btn-icon rounded-circle mt-0 mt-md-2"
                        @click="deleteRow(item)"
                      >
                        <feather-icon icon="XIcon" />
                      </b-button>
                    </div>
                  </b-col>
                </b-row>
              </b-form>
            </div>
            <div class="footer-promotion">
              <b-button
                v-if="!isPromotion"
                v-ripple.400="'rgba(113, 102, 240, 0.15)'"
                class="mr-1"
                variant="outline-primary"
                @click="addPromotion"
              >
                <feather-icon
                  icon="CheckIcon"
                  class="mr-50"
                />
                <span class="align-middle">Tạo mới</span>
              </b-button>
              <b-button
                v-if="isPromotion"
                v-ripple.400="'rgba(113, 102, 240, 0.15)'"
                class="mr-1"
                variant="outline-primary"
                @click="updatePromotion"
              >
                <feather-icon
                  icon="CheckIcon"
                  class="mr-50"
                />
                <span class="align-middle">Cập nhật</span>
              </b-button>
              <b-button
                v-ripple.400="'rgba(113, 102, 240, 0.15)'"
                variant="outline-danger"
                @click="closeModal"
              >
                <feather-icon
                  icon="XIcon"
                  class="mr-50"
                />
                <span class="align-middle">Đóng</span>
              </b-button>
            </div>
          </div>
        </validation-observer>
      </div>
    </b-modal>
  </div>
</template>
<script>
import { ValidationProvider, ValidationObserver } from 'vee-validate'
import {
  BCard,
  BButton,
  VBTooltip, BModal, BFormGroup, BFormInput, BRow, BCol, BForm, BTable, BPagination, BFormTextarea,
} from 'bootstrap-vue'
import Ripple from 'vue-ripple-directive'
import flatPickr from 'vue-flatpickr-component'
import { Vietnamese } from 'flatpickr/dist/l10n/vn'
import vSelect from 'vue-select'
import useJwt from '@/auth/jwt/useJwt'
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'
import { required, max } from '@validations'
// eslint-disable-next-line import/no-extraneous-dependencies
import moment from 'moment'

export default {
  components: {
    BCard,
    BButton,
    BModal,
    BFormGroup,
    BFormInput,
    BRow,
    BCol,
    flatPickr,
    vSelect,
    BForm,
    BTable,
    BPagination,
    BFormTextarea,
    ValidationProvider,
    ValidationObserver,
  },
  directives: {
    'b-tooltip': VBTooltip,
    Ripple,
  },
  data() {
    return {
      nextTodoId: 2,
      isPromotion: false,
      listPromotion: [],
      currentPage: 1,
      perPage: 10,
      isValidateDate: false,
      required,
      max,
      fields: [
        {
          key: 'index', label: 'STT', thClass: 'customThIndexs',
        },
        {
          key: 'name', label: 'Tên chương trình', thClass: 'customThName',
        },
        {
          key: 'startDate', label: 'Ngày bắt đầu', thClass: 'customThDate',
        },
        {
          key: 'endDate', label: 'Ngày kết thúc', thClass: 'customThDate',
        },
        {
          key: 'hanhdong', label: 'Hành động', thClass: 'customThAction',
        },
      ],
      totalCount: 0,
      modelPromotionSearch: {
        endDate: '',
        name: '',
        startDate: '',
        pageIndex: 0,
        pageSize: 0,
      },
      modelPromotion: {
        endDate: '',
        name: '',
        content: '',
        promotionSchoolDetails: [
          {
            id: 1,
            percent: 0,
            fromDate: '',
            toDate: '',
            type: null,
            isNumber: false,
            isValidate: false,
          },
        ],
        startDate: '',
      },
      listTypePromotion: [
        {
          id: 1,
          name: 'Giảm trừ học phí',
          value: 0,
        },
        {
          id: 2,
          name: 'Giảm tiền xe bus',
          value: 1,
        },
      ],
      configFlatpickr: {
        wrap: true,
        altFormat: 'd/m/Y',
        altInput: true,
        locale: Vietnamese,
        allowInput: true,
      },
      idPromotion: null,
    }
  },
  computed: {
    rows() {
      return this.listPromotion.length
    },
  },
  created() {
    this.getListPromotion()
  },
  methods: {
    getFormAddPromotion() {
      this.modelPromotion = {
        name: '',
        endDate: '',
        startDate: '',
        promotionSchoolDetails: [
          {
            id: 1,
            toDate: '',
            fromDate: '',
            percent: 0,
            content: '',
            type: null,
          },
        ],
      }
      this.isPromotion = false
      this.$refs['modal-promotion'].show()
    },
    repeatAgain() {
      this.modelPromotion.promotionSchoolDetails.push({ id: this.nextTodoId += this.nextTodoId, type: null, percent: 0 })
    },
    closeModal() {
      this.$refs['modal-promotion'].hide()
    },
    addPromotion() {
      if (this.modelPromotion.startDate && this.modelPromotion.endDate) {
        const startDate = new Date(this.modelPromotion.startDate)
        const endDate = new Date(this.modelPromotion.endDate)
        if (startDate > endDate) {
          this.isValidateDate = true
          return
        }
      }
      for (let i = 0; i < this.modelPromotion.promotionSchoolDetails.length; i++) {
        const dateForm = new Date(this.modelPromotion.promotionSchoolDetails[i].fromDate)
        const dateTo = new Date(this.modelPromotion.promotionSchoolDetails[i].toDate)
        if (dateForm > dateTo) {
          this.modelPromotion.promotionSchoolDetails[i].isValidate = true
          return
        }
      }
      for (let i = 0; i < this.modelPromotion.promotionSchoolDetails.length; i++) {
        if (this.modelPromotion.promotionSchoolDetails[i].type === null) {
          this.$toast({
            component: ToastificationContent,
            position: 'top-right',
            props: {
              title: 'Lỗi !',
              icon: 'XIcon',
              variant: 'danger',
              text: 'Loại khuyến mãi không được để trống',
            },
          })
          return
        }
      }
      this.$refs.modelPromotion.validate().then(success => {
        if (success) {
          this.$crm.post('promotion-school/create', this.modelPromotion).then(() => {
            this.$toast({
              component: ToastificationContent,
              position: 'top-right',
              props: {
                title: 'Thành công!',
                icon: 'CheckIcon',
                variant: 'success',
                text: 'Tạo mới chương trình khuyến mãi thành công',
              },
            })
            this.$refs['modal-promotion'].hide()
            this.getListPromotion()
          })
        }
      })
    },
    onCompareFormDateToDate() {
      if (this.modelPromotion.startDate && this.modelPromotion.endDate) {
        const startDate = new Date(this.modelPromotion.startDate)
        const endDate = new Date(this.modelPromotion.endDate)
        if (startDate > endDate) {
          this.isValidateDate = true
        } else {
          this.isValidateDate = false
        }
      }
    },
    getListPromotion() {
      this.$crm.post('promotion-school/find-by-condition', this.modelPromotionSearch).then(res => {
        this.listPromotion = res.data
        this.totalCount = this.listPromotion.length
        for (let i = 0; i < this.listPromotion.length; i++) {
          if (this.listPromotion[i].startDate) {
            this.listPromotion[i].startDate = moment(res.data[i].startDate).format('DD-MM-YYYY')
          }
          if (this.listPromotion[i].endDate) {
            this.listPromotion[i].endDate = moment(res.data[i].endDate).format('DD-MM-YYYY')
          }
        }
      })
    },
    onCompareDate() {
      for (let i = 0; i < this.modelPromotion.promotionSchoolDetails.length; i++) {
        const dateStart = new Date(this.modelPromotion.promotionSchoolDetails[i].fromDate)
        const dateEnd = new Date(this.modelPromotion.promotionSchoolDetails[i].toDate)
        if (dateStart > dateEnd) {
          this.modelPromotion.promotionSchoolDetails[i].isValidate = true
        } else {
          this.modelPromotion.promotionSchoolDetails[i].isValidate = false
        }
      }
    },
    onChangeFormatNumber(value) {
      const nb = value.replace(/[^0-9]/gi, '')
      const fn = parseFloat(nb === '' ? 0 : nb)
      if (fn > 0) {
        for (let i = 0; i < this.modelPromotion.promotionSchoolDetails.length; i++) {
          if (this.modelPromotion.promotionSchoolDetails[i].percent > 100) {
            this.modelPromotion.promotionSchoolDetails[i].percent = 0
            return
          }
        }
      }
      if (fn === 0) {
        for (let i = 0; i < this.modelPromotion.promotionSchoolDetails.length; i++) {
          if (this.modelPromotion.promotionSchoolDetails[i].percent > 0) {
            // eslint-disable-next-line no-self-assign
            this.modelPromotion.promotionSchoolDetails[i].percent = this.modelPromotion.promotionSchoolDetails[i].percent
          } else {
            this.modelPromotion.promotionSchoolDetails[i].percent = 0
          }
        }
      }
    },
    updatePromotion() {
      if (this.modelPromotion.startDate && this.modelPromotion.endDate) {
        const startDate = new Date(this.modelPromotion.startDate)
        const endDate = new Date(this.modelPromotion.endDate)
        if (startDate > endDate) {
          this.isValidateDate = true
          return
        }
      }
      for (let i = 0; i < this.modelPromotion.promotionSchoolDetails.length; i++) {
        if (this.modelPromotion.promotionSchoolDetails[i].fromDate && this.modelPromotion.promotionSchoolDetails[i].toDate) {
          const dateForm = new Date(this.modelPromotion.promotionSchoolDetails[i].fromDate)
          const dateTo = new Date(this.modelPromotion.promotionSchoolDetails[i].toDate)
          if (dateForm > dateTo) {
            this.modelPromotion.promotionSchoolDetails[i].isValidate = true
            return
          }
        }
      }
      this.$refs.modelPromotion.validate().then(success => {
        if (success) {
          this.$crm.post(`promotion-school/update/${this.idPromotion}`, this.modelPromotion).then(() => {
            this.$toast({
              component: ToastificationContent,
              position: 'top-right',
              props: {
                title: 'Thành công!',
                icon: 'CheckIcon',
                variant: 'success',
                text: 'Cập nhật chương trình khuyến mãi thành công',
              },
            })
            this.$refs['modal-promotion'].hide()
            this.getListPromotion()
          })
        }
      })
    },
    getFormUpdatePromotion(item) {
      this.isPromotion = true
      this.idPromotion = item.id
      this.$crm.get(`promotion-school/find-by-id/${item.id}`).then(res => {
        this.modelPromotion = res.data
        this.$refs['modal-promotion'].show()
      })
    },
    deletePromotion(item) {
      this.$swal({
        title: 'Xóa chương trình khuyến mãi',
        text: `Bạn có muốn xóa chương trình khuyến mãi ${item.name} ?`,
        icon: 'warning',
        showCancelButton: true,
        confirmButtonText: 'Đồng ý',
        cancelButtonText: 'Hủy bỏ',
        customClass: {
          confirmButton: 'btn btn-primary',
          cancelButton: 'btn btn-outline-danger ml-1',
        },
        buttonsStyling: false,
      }).then(result => {
        if (result.value) {
          this.$crm.get(`promotion-school/delete/${item.id}`).then(() => {
            this.getListPromotion()
            this.$swal({
              icon: 'success',
              title: 'Xóa chương trình khuyến mãi!',
              text: 'Xóa chương trình khuyến mãi thành công',
              showConfirmButton: false,
              timer: 1500,
            })
          })
        }
      })
    },
    deleteRow(item) {
      if (item.id > 1) {
        const indexDelete = this.modelPromotion.promotionSchoolDetails.indexOf(item)
        // if (this.modelPromotion.promotionsSchoolDetails.length > 1) {
        //   this.modelPromotion.promotionsSchoolDetails.splice(index, 1)
        //   this.trTrimHeight(this.$refs.row[0].offsetHeight)
        // }
        if (indexDelete > -1) {
          this.modelPromotion.promotionSchoolDetails.splice(indexDelete, 1) // 2nd parameter means remove one item only
        }
      }
    },
  },
}
</script>

<style lang="scss">
@import '../../@core/scss/vue/libs/vue-flatpicker';
@import 'src/@core/scss/vue/libs/vue-select.scss';
@import '../../@core/scss/custom/promotion';
.repeater-form {
  transition: .35s height;
}
</style>
